<template>
  <div>
    <h3>Etape 4 - Synthèse et validation</h3>
    <!-- <ValidateForm @validated="submit"> -->
    <h4>
      1) Informations générales -
      <a
        href=""
        style="font-size: 0.8rem;"
        @click.prevent="$emit('goToStep', 1)"
        >Modifier</a
      >
    </h4>
    <v-row>
      <v-col cols="12">
        <v-row class="py-2">
          <v-col cols="12" md="6">
            <div>
              <span class="font-weight-bold">Nom de la structure:</span>
              {{ structure.NomStructure }}
            </div>
            <div>
              <span class="font-weight-bold">Complément de nom:</span>
              {{ structure.NomStructureComplement }}
            </div>
            <div>
              <span class="font-weight-bold">Type de la structure:</span>
              {{
                labelTypeStructure
              }}
            </div>
            <div>
              <span class="font-weight-bold"
                >Présentation de la structure:</span
              >
              {{ structure.INFO_Presentation }}
            </div>
            <div>
              <span class="font-weight-bold">SIRET de la structure:</span>
              {{ structure.JUR_Siret }}
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <div>
              <span class="font-weight-bold">Agrément jeunesse et sport:</span>
              {{ structure.JUR_AgrementJeunesseSports }}
            </div>

            <div>
              <span class="font-weight-bold">Equivalent temps plein:</span>
              {{ affi.SAL_EquivalentTempsPlein }}
            </div>

            <div>
              <span class="font-weight-bold"
                >Organisme de la formation professionnelle:</span
              >
              {{
                affi.ID_CollecteurFormationProfessionnelle
                  ? affi.ID_CollecteurFormationProfessionnelle
                  : ''
              }}
            </div>
          </v-col>
        </v-row>

        <v-row class="py-2">
          <v-col cols="12" md="6">
            <div>
              <span class="font-weight-bold"
                >Accueil de personnes en situation de handicap:</span
              >
              {{ affi.CLUB_EST_AcceuillantHandicape ? 'Oui' : 'Non' }}
            </div>

            <div>
              <span class="font-weight-bold">Mineurs acceptés: </span
              >{{ affi.CLUB_EST_AcceuillantMineur ? 'Oui' : 'Non' }}
            </div>

            <div>
              <span class="font-weight-bold">Âge minimum: </span
              >{{ affi.CLUB_AcceuillantMineurAge }} {{ mineurAge }}
            </div>

            <div>
              <span class="font-weight-bold"
                >Propose les passeports escalade:</span
              >
              {{ affi.CLUB_EST_AcceptePassSportCulture ? 'Oui' : 'Non' }}
            </div>

            <div>
              <span class="font-weight-bold">Prêt de matériel (EPI):</span>
              {{ affi.CLUB_EST_PretMateriel ? 'Oui' : 'Non' }}
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div>
              <span class="font-weight-bold"
                >Convention avec les Caisses d'Allocation Familiales:</span
              >
              {{ affi.CLUB_EST_ConventionAllocationFamilliale ? 'Oui' : 'Non' }}
            </div>

            <div>
              <span class="font-weight-bold"
                >Accepte les chèques vacances:</span
              >
              {{ affi.CLUB_EST_AccepteChequesVacances ? 'Oui' : 'Non' }}
            </div>

            <div>
              <span class="font-weight-bold">Accepte les coupons sport:</span>
              {{ affi.CLUB_EST_AccepteCouponSport ? 'Oui' : 'Non' }}
            </div>

            <div>
              <span class="font-weight-bold"
                >Accepte les pass'sport culture:</span
              >
              {{ affi.CLUB_EST_AcceptePassSportCulture ? 'Oui' : 'Non' }}
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <h4>
      2) Contact -
      <a
        href=""
        style="font-size: 0.8rem;"
        @click.prevent="$emit('goToStep', 2)"
        >Modifier</a
      >
    </h4>
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="6">
            <div v-if="correspondant">
              <span class="font-weight-bold">Correspondant:</span><br />
              {{ correspondant.CT_Nom + ' ' + correspondant.CT_Prenom }}<br />
              {{ correspondant.CT_Email }}
            </div>
          </v-col>

          <v-col cols="12" md="6">
            <div>
              <span class="font-weight-bold">Adresse: </span
              >{{ adresse.Adresse1 }}
            </div>
            <div>
              <span class="font-weight-bold">Complément d'adresse: </span
              >{{ adresse.Adresse2 }}
            </div>
            <div>
              <span class="font-weight-bold">Code postal: </span
              >{{ adresse.CodePostal }}
            </div>
            <div>
              <span class="font-weight-bold">Ville: </span>{{ adresse.Ville }}
            </div>
            <div>
              <span class="font-weight-bold">Département: </span
              >{{
                adresse.Departement ? adresse.Departement.NomDepartement : ''
              }}
            </div>
            <div v-if="adresse.ADR_Pays">
              <span class="font-weight-bold">Pays: </span
              >{{ adresse.ADR_Pays.NomPays }}
              <country-flag
                v-if="adresse && adresse.ADR_Pays"
                style="margin: -16px;"
                :country="adresse.ADR_Pays.CodePays"
                size="small"
              />
            </div>
          </v-col>

          <v-col cols="12" class="pt-6">
            <v-row v-if="this.$route.params.type === 'association'">
              <v-col cols="12" md="4">
                <div v-if="president">
                  <span class="font-weight-bold">Président:</span><br />
                  {{ president.CT_Nom + ' ' + president.CT_Prenom }}<br />
                  {{ president.CT_Email }}
                </div>
              </v-col>

              <v-col cols="12" md="4">
                <div v-if="secretaire">
                  <span class="font-weight-bold">Secrétaire:</span><br />
                  {{ secretaire.CT_Nom + ' ' + secretaire.CT_Prenom }}<br />
                  {{ secretaire.CT_Email }}
                </div>
              </v-col>

              <v-col cols="12" md="4">
                <div v-if="tresorier">
                  <span class="font-weight-bold">Trésorier:</span><br />
                  {{ tresorier.CT_Nom + ' ' + tresorier.CT_Prenom }}<br />
                  {{ tresorier.CT_Email }}
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <h4>
      3) Documents à joindre -
      <a
        href=""
        style="font-size: 0.8rem;"
        @click.prevent="$emit('goToStep', 3)"
        >Modifier</a
      >
    </h4>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Type de document</th>
          <th scope="col">Fichier</th>
          <th scope="col">Date de signature</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="documents.DOC_Status">
          <th>Status</th>
          <td>
            <span
              v-if="documents.DOC_Status"
              @click.prevent="
                openModalUpload(
                  documents.DOC_Status && documents.DOC_Status.fileDataURL,
                  documents.DOC_Status.file.name
                )
              "
            >
              {{ documents.DOC_Status.file.name }}</span
            >
          </td>
          <td>
            {{
              $moment(documents.DOC_Status.validationDate).format('DD/MM/YYYY')
            }}
          </td>
        </tr>
        <tr v-if="documents.DOC_RIB">
          <th>RIB</th>
          <td>
            <span
              v-if="documents.DOC_RIB"
              @click.prevent="
                openModalUpload(
                  documents.DOC_RIB && documents.DOC_RIB.fileDataURL,
                  documents.DOC_RIB.file.name
                )
              "
            >
              {{ documents.DOC_RIB.file.name }}
            </span>
          </td>
          <td>---------</td>
        </tr>
        <tr v-if="documents.DOC_SEPA">
          <th>SEPA</th>
          <td>
            <span
              v-if="documents.DOC_SEPA"
              @click.prevent="
                openModalUpload(
                  documents.DOC_SEPA && documents.DOC_SEPA.fileDataURL,
                  documents.DOC_SEPA.file.name
                )
              "
            >
              {{ documents.DOC_SEPA.file.name }}
            </span>
          </td>
          <td>---------</td>
        </tr>
        <tr v-if="documents.DOC_JO">
          <th>Extrait de parution au JO</th>
          <td>
            <span
              v-if="documents.DOC_JO"
              @click.prevent="
                openModalUpload(
                  documents.DOC_JO && documents.DOC_JO.fileDataURL,
                  documents.DOC_JO.file.name
                )
              "
            >
              {{ documents.DOC_JO.file.name }}
            </span>
          </td>
          <td>
            <span v-if="documents.DOC_JO">
              {{
                $moment(documents.DOC_JO.validationDate).format('DD/MM/YYYY')
              }}
            </span>
          </td>
        </tr>
        <tr v-if="documents.DOC_Diplomes">
          <th>Diplômes des encadrants</th>
          <td>
            <span
              v-if="documents.DOC_Diplomes"
              @click.prevent="
                openModalUpload(
                  documents.DOC_Diplomes && documents.DOC_Diplomes.fileDataURL,
                  documents.DOC_Diplomes.file.name
                )
              "
            >
              {{ documents.DOC_Diplomes.file.name }}
            </span>
          </td>
          <td>
            <span v-if="documents.DOC_Diplomes">
              {{
                $moment(documents.DOC_Diplomes.validationDate).format(
                  'DD/MM/YYYY'
                )
              }}
            </span>
          </td>
        </tr>
        <tr v-if="documents.DOC_RecepissePrefectoral">
          <th>Récépissé préfectoral de déclaration</th>
          <td>
            <span
              v-if="documents.DOC_RecepissePrefectoral"
              @click.prevent="
                openModalUpload(
                  documents.DOC_RecepissePrefectoral &&
                    documents.DOC_RecepissePrefectoral.fileDataURL,
                  documents.DOC_RecepissePrefectoral.file.name
                )
              "
            >
              {{ documents.DOC_RecepissePrefectoral.file.name }}
            </span>
          </td>
          <td>
            <span v-if="documents.DOC_RecepissePrefectoral">
              {{
                $moment(
                  documents.DOC_RecepissePrefectoral.validationDate
                ).format('DD/MM/YYYY')
              }}
            </span>
          </td>
        </tr>
        <tr v-if="documents.DOC_Reglement">
          <th>Règlement intérieur de la section</th>
          <td>
            <span
              v-if="documents.DOC_Reglement"
              @click.prevent="
                openModalUpload(
                  documents.DOC_Reglement &&
                    documents.DOC_Reglement.fileDataURL,
                  documents.DOC_Reglement.file.name
                )
              "
            >
              {{ documents.DOC_Reglement.file.name }}
            </span>
          </td>
          <td>
            <span v-if="documents.DOC_Reglement">
              {{
                $moment(documents.DOC_Reglement.validationDate).format(
                  'DD/MM/YYYY'
                )
              }}
            </span>
          </td>
        </tr>
        <tr v-if="documents.DOC_Cartes">
          <th>Copie des cartes professionnelles</th>
          <td>
            <span
              v-if="documents.DOC_Cartes"
              @click.prevent="
                openModalUpload(
                  documents.DOC_Cartes && documents.DOC_Cartes.fileDataURL,
                  documents.DOC_Cartes.file.name
                )
              "
            >
              {{ documents.DOC_Cartes.file.name }}
            </span>
          </td>
          <td>
            <span v-if="documents.DOC_Cartes">
              {{
                $moment(documents.DOC_Cartes.validationDate).format(
                  'DD/MM/YYYY'
                )
              }}
            </span>
          </td>
        </tr>
        <tr v-if="documents.DOC_Attestation">
          <th>Attestation d'assurance</th>
          <td>
            <span
              v-if="documents.DOC_Attestation"
              @click.prevent="
                openModalUpload(
                  documents.DOC_Attestation &&
                    documents.DOC_Attestation.fileDataURL,
                  documents.DOC_Attestation.file.name
                )
              "
            >
              {{ documents.DOC_Attestation.file.name }}
            </span>
          </td>
          <td>
            <span v-if="documents.DOC_Attestation">
              {{
                $moment(documents.DOC_Attestation.validationDate).format(
                  'DD/MM/YYYY'
                )
              }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <v-row>
      <v-col>
        <p class="font-weight-bold">IBAN:</p>
      </v-col>
      <v-col>{{ documents.IBAN }} </v-col>
      <v-col>
        <p class="font-weight-bold">BIC:</p>
      </v-col>
      <v-col>{{ documents.BIC }} </v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
      <v-col></v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <p class="font-weight-bold">
          Le paiement des frais d'affiliation ({{tarifAffiliation}} €) sera prélevé sur le compte bancaire de la structure.
        </p>
      </v-col>
      <div class="d-flex flex-column mx-6">
        <div class="d-flex">
          <div class="checkbox-container">
            <v-checkbox
              v-model="accept"
              label="J'accepte d'être en prélèvement automatique pour régler (au siège national de la FFME) les dépenses de ma structure."
            ></v-checkbox>
          </div>
          <div class="checkbox-container">
            <v-checkbox
              v-model="exact"
              label="J'atteste l'exactitude des informations portées sur cette fiche et demande l'affiliation de notre structure à la FFME."
            ></v-checkbox>
          </div>
        </div>
        <div class="d-flex">
          <div class="checkbox-container">
            <v-checkbox
              v-model="validation1"
              label="Je certifie pouvoir représenter légalement l’association."
            ></v-checkbox>
          </div>
        </div>
        <div class="d-flex align-center my-2">
          <h6>Mandat du représentant légal (si vous n'êtes pas le représentant légal de l'association)</h6>
          <v-chip
            @click.prevent="!validation1 ? modalIsOpen_DOC_RIB = true : null"
            class="ml-5"
            small
            :style="validation1 ? 'cursor: not-allowed;' : ''"
            >{{ currentFileName() }}</v-chip
          >
          <ModalFiles
            :key="modalKey_DOC_RIB"
            :isOpen="modalIsOpen_DOC_RIB"
            @onClose="modalIsOpen_DOC_RIB = false"
            @onFileSelected="(file) => fileSelected(file)"
          ></ModalFiles>
        </div>
        <div class="d-flex">
          <v-checkbox
            v-model="validation2"
            :readonly="!openedLink.some((link) => link === 1)"
          >
            <template v-slot:label>
              <div>
                J'ai pris connaissance du
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      @click.stop="
                        openLink(
                          'https://www.montagne-escalade.com/site/BO/documents/contrat-engagement-republicain.pdf',
                          1
                        )
                      "
                      style="color: #1976d2;"
                      v-on="on"
                      v-text="'contrat d’engagement républicain '"
                    />
                  </template>
                  Veuillez consulter le dit contrat pour valider l'affiliation
                </v-tooltip>
                et en tant que représentant légal, j'engage le club à respecter
                ledit contrat.
              </div>
            </template>
          </v-checkbox>
        </div>
        <div class="d-flex" v-if="this.$route.params.type === 'association'">
          <v-checkbox
            v-model="validation3"
            :readonly="!openedLink.some((link) => link === 2)"
          >
            <template v-slot:label>
              <div>
                J'ai pris connaissance du
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      @click.stop="
                        openLink(
                          'https://www.montagne-escalade.com/site/BO/documents/contrat-club.pdf',
                          2
                        )
                      "
                      style="color: #1976d2;"
                      v-on="on"
                      v-text="'contrat club '"
                    />
                  </template>
                  Veuillez consulter le dit contrat pour valider l'affiliation
                </v-tooltip>
                et en tant que représentant légal, j'engage le club à respecter
                ledit contrat.
              </div>
            </template>
          </v-checkbox>
        </div>
        <div v-else class="d-flex">
          <v-checkbox
            v-model="validation4"
            :readonly="!openedLink.some((link) => link === 3)"
          >
            <template v-slot:label>
              <div>
                J'ai pris connaissance de la
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <a
                      @click.stop="
                        openLink(
                          'https://www.montagne-escalade.com/site/BO/documents/convention-etablissement.pdf',
                          3
                        )
                      "
                      style="color: #1976d2;"
                      v-on="on"
                      v-text="'convention d’établissement '"
                    />
                  </template>
                  Veuillez consulter la dite convention pour valider
                  l'affiliation
                </v-tooltip>
                et en tant que représentant légal, j'engage le club à respecter
                ladite convention.
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
    </v-row>

    <v-row> </v-row>

    <v-row class="my-4" no-gutters>
      <v-btn dark @click.prevent="$emit('onPrevious')">Retour</v-btn>
      <v-btn
        @click.prevent="submit"
        class="btn btn-primary ml-auto"
        :loading="loading"
        :disabled="!canContinue"
        >Valider</v-btn
      >
    </v-row>

    <!-- </ValidateForm> -->
    <!---dialog view documents -->
    <v-dialog
      v-if="modalUploadDocumentIsOpen"
      v-model="modalUploadDocumentIsOpen"
      @click:outside="closeModal"
      style="width: 70%;"
    >
      <div style="position: fixed; z-index: 10; height: 70px;">
        <div style="display: flex;" class="menu-preview">
          <div class="px-3" style="margin-left: auto;">
            <v-icon
              class="close-icon"
              style="
                color: #fff;
                margin-top: -23px !important;
                margin-left: auto;
              "
              @click="closeModal"
              large
              >mdi-close</v-icon
            >
          </div>
        </div>
      </div>

      <div
        class="modal__content"
        style="background: rgba(180, 180, 180, 1); height: 100%;"
      >
        <div v-if="fileType == 'pdf' && numPages">
          <div style="margin-top: 80px;">
            <pdf
              v-for="i in numPages"
              :key="i"
              :src="src"
              :page="i"
              :id="'pdf_' + i"
              style="display: block; margin: 20px auto; max-width: 80%;"
              @num-pages="pageCount = $event"
              @page-loaded="currentPage = $event"
            ></pdf>
          </div>
        </div>
        <div v-else style="margin-top: 80px;">
          <v-img style="margin: 0 auto;" max-width="90%" :src="fileURL"></v-img>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  error_notification,
  success_notification,
} from "@/plugins/notifications";
import CountryFlag from "vue-country-flag";
import pdf from "vue-pdf";
import ModalFiles from "@/components/utils/modals/fileUpload/fileUpload.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  components: { ModalFiles, CountryFlag, pdf },
  data: () => ({
    accept: false,
    exact: false,
    loading: false,
    modalUploadDocumentIsOpen: false,
    fileURL: '',
    src: null,
    currentPage: 0,
    pageCount: 0,
    numPages: undefined,
    fileType: null,
    validation1: false,
    validation2: false,
    validation3: false,
    validation4: false,
    openedLink: [],
    modalIsOpen_DOC_RIB: false,
    modalKey_DOC_RIB: 77,
    selectedFile: null,
    tarifStructure: null,
  }),

  computed: {
    ...mapGetters("saisons", ["getSaison", 'currentSaison']),

    canContinue() {
      const validations = [this.accept, this.exact, this.validation1];
      return validations.every((value) => value);
    },

    labelTypeStructure() {
      if (this.$route.params.type !== 'association') {
        return 'Établissement affilié';
      }
      if (this.$route.params.type === 'association' && this.affiliation?.structure?.EST_SectionAssociation) {
        return "Section d'association";
      } else {
        return 'Association';
      }
    },

    structure() {
      if (!this.affiliation.structure) return false;
      return this.affiliation.structure;
    },

    mineurAge() {
      if (this.affiliation.affiliation?.CLUB_AcceuillantMineurAge <= 1) {
        return 'an';
      } else {
        return 'ans';
      }
    },

    affi() {
      if (!this.affiliation.affiliation) return false;
      return this.affiliation.affiliation;
    },

    correspondant() {
      if (
        !this.affiliation.utilisateurs ||
        !this.affiliation.utilisateurs.correspondant
      )
        return false;
      return this.affiliation.utilisateurs.correspondant;
    },

    president() {
      if (
        !this.affiliation.utilisateurs ||
        !this.affiliation.utilisateurs.president
      )
        return false;
      return this.affiliation.utilisateurs.president;
    },

    secretaire() {
      if (
        !this.affiliation.utilisateurs ||
        !this.affiliation.utilisateurs.secretaire
      )
        return false;
      return this.affiliation.utilisateurs.secretaire;
    },

    tresorier() {
      if (
        !this.affiliation.utilisateurs ||
        !this.affiliation.utilisateurs.tresorier
      )
        return false;
      return this.affiliation.utilisateurs.tresorier;
    },

    adresse() {
      if (!this.affiliation.adresse) return false;
      return this.affiliation.adresse;
    },

    tarifs() {
      if (!this.affiliation.tarifs) return false;
      return this.affiliation.tarifs;
    },
    documents() {
      if (!this.affiliation.documents) return false;
      return this.affiliation.documents;
    },
    tarifAffiliation() {
      return this.affiliation.tarifStructure / 100;
    },
  },
  methods: {
    ...mapActions("basics", [
      "getCurrentAffiliationTarif",
    ]),
    fileSelected(file) {
      this.selectedFile = file;
    },
    currentFileName() {
      if (this.selectedFile) {
        return this.selectedFile.file.name;
      }
      return "Veuillez choisir un fichier";
    },
    openLink(link, value) {
      window.open(link);
      this.openedLink.push(value);
    },
    validate() {
      success_notification(
        "La signature des documents a bien été prise en compte"
      );
      if (this.selectedFile) {
        this.$emit("onSetData", { representant: { ...this.selectedFile } });
      }
      this.$emit("submit");
    },
    submit() {
      if (this.$route.params.type === "association") {
        return this.validation2 && this.validation3
          ? this.validate()
          : error_notification("Une ou plusieurs cases ne sont pas cochées");
      }
      return this.validation2 && this.validation4
        ? this.validate()
        : error_notification("Une ou plusieurs cases ne sont pas cochées");
    },
    openModalUpload(fileDataURL, fileName) {
      this.fileURL = fileDataURL;
      this.fileType = fileName.split('.').pop();
      this.src = pdf.createLoadingTask(this.fileURL);
      this.src.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
      this.modalUploadDocumentIsOpen = true;
    },
    closeModal() {
      this.modalUploadDocumentIsOpen = false;
    },
  },

  props: {
    affiliation: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.modal__content {
  overflow-x: hidden;
}
</style>

<style scoped>
.checkbox-container {
  width: 50%;
}
.v-input__slot {
  max-width: 100%;
  padding: 0 !important;
}

h4 {
  background: lightgrey;
  padding: 0.6em;
}

hr {
  margin: 0;
  padding: 0;
  width: 100%;
}

.menu-preview {
  background: #002755;
  padding: 20px;
}
</style>
