<template>
  <div>
    <h3>Etape 3 - Documents officiels</h3>
    <ValidateForm v-if="mounted" @validated="submit">
      <!-- documents for all type of structure  -->
      <v-row>
        <v-col cols="5" class="d-flex align-center pt-0">
          <h6>
            Joindre les statuts en vigueur au
            {{ $moment().format("DD/MM/YYYY") }}*
          </h6>
        </v-col>
        <v-col cols="7" md="7" class="pt-0">
          <v-chip
            @click.prevent="modalIsOpen_DOC_Status = true"
            class="ma-2"
            small
            >{{ currentFileName("selectedFile_DOC_Status") }}</v-chip
          >
          <v-chip v-if="selectedFile_DOC_Status" class="ma-2" small>
            <span class="font-weight-bold mr-2">Date de signature:</span
            >{{ currentFileDate("selectedFile_DOC_Status") }}
          </v-chip>
        </v-col>
        <ModalFiles
          :key="modalKey_DOC_Status"
          :isOpen="modalIsOpen_DOC_Status"
          @onClose="closeModal('modalIsOpen_DOC_Status')"
          @onFileSelected="
            (file) => fileSelected('selectedFile_DOC_Status', file)
          "
          withValidationDate
        ></ModalFiles>
      </v-row>

      <v-row>
        <v-col cols="5" class="d-flex align-center pt-0"
          ><h6>Joindre un RIB*</h6>
        </v-col>
        <v-col cols="7" md="7" class="pt-0">
          <v-chip
            @click.prevent="modalIsOpen_DOC_RIB = true"
            class="ma-2"
            small
            >{{ currentFileName("selectedFile_DOC_RIB") }}</v-chip
          >
        </v-col>
        <ModalFiles
          :key="modalKey_DOC_RIB"
          :isOpen="modalIsOpen_DOC_RIB"
          @onClose="closeModal('modalIsOpen_DOC_RIB')"
          @onFileSelected="(file) => fileSelected('selectedFile_DOC_RIB', file)"
        ></ModalFiles>
      </v-row>

      <v-row>
        <v-col class="">
          <h6>IBAN: *</h6>
        </v-col>
        <v-col md="5" class="pt-0">
          <InputText
            v-model="IBAN"
            rules="required"
          ></InputText>
        </v-col>
        <v-col class="">
          <h6>BIC: *</h6>
        </v-col>
        <v-col md="3" class="pt-0">
          <InputText
            v-model="BIC"
            rules="required"
          ></InputText>
        </v-col>
        <v-col></v-col>
        <v-col></v-col>
      </v-row>
      <v-row> </v-row>

      <!-- documents for both association and section d'association  -->

      <div
        v-if="
          this.$route.params.type === 'association'
        "
      >
        <v-row>
          <v-col cols="5" class="d-flex align-center pt-0"
            ><h6>Joindre un mandat de prélévement SEPA*</h6>
          </v-col>
          <v-col cols="7" md="7" class="pt-0">
            <v-chip
              @click.prevent="modalIsOpen_DOC_SEPA = true"
              class="ma-2"
              small
              >{{ currentFileName("selectedFile_DOC_SEPA") }}</v-chip
            >
          </v-col>
          <ModalFiles
            :key="modalKey_DOC_SEPA"
            :isOpen="modalIsOpen_DOC_SEPA"
            @onClose="closeModal('modalIsOpen_DOC_SEPA')"
            @onFileSelected="(file) => fileSelected('selectedFile_DOC_SEPA', file)"
          ></ModalFiles>
        </v-row>
        <v-row>
          <v-col cols="5" class="d-flex align-center pt-0"
            ><h6>Joindre un extrait de parution aux JO*</h6>
          </v-col>
          <v-col cols="7" md="7" class="pt-0">
            <v-chip
              @click.prevent="modalIsOpen_DOC_JO = true"
              class="ma-2"
              small
              >{{ currentFileName("selectedFile_DOC_JO") }}</v-chip
            >
            <v-chip v-if="selectedFile_DOC_JO" class="ma-2" small>
              <span class="font-weight-bold mr-2">Date de signature:</span
              >{{ currentFileDate("selectedFile_DOC_JO") }}
            </v-chip>
          </v-col>
          <ModalFiles
            :key="modalKey_DOC_JO"
            :isOpen="modalIsOpen_DOC_JO"
            @onClose="closeModal('modalIsOpen_DOC_JO')"
            @onFileSelected="
              (file) => fileSelected('selectedFile_DOC_JO', file)
            "
            withValidationDate
          ></ModalFiles>
        </v-row>

        <v-row>
          <v-col cols="5" class="d-flex align-center pt-0"
            ><h6>Joindre un récépissé préfectoral de déclaration*</h6>
          </v-col>
          <v-col cols="7" md="7" class="pt-0">
            <v-chip
              @click.prevent="modalIsOpen_DOC_RecepissePrefectoral = true"
              class="ma-2"
              small
              >{{
                currentFileName("selectedFile_DOC_RecepissePrefectoral")
              }}</v-chip
            >
            <v-chip
              v-if="selectedFile_DOC_RecepissePrefectoral"
              class="ma-2"
              small
            >
              <span class="font-weight-bold mr-2">Date de signature:</span
              >{{ currentFileDate("selectedFile_DOC_RecepissePrefectoral") }}
            </v-chip>
          </v-col>
          <ModalFiles
            :key="modalKey_DOC_RecepissePrefectoral"
            :isOpen="modalIsOpen_DOC_RecepissePrefectoral"
            @onClose="closeModal('modalIsOpen_DOC_RecepissePrefectoral')"
            @onFileSelected="
              (file) =>
                fileSelected('selectedFile_DOC_RecepissePrefectoral', file)
            "
            withValidationDate
          ></ModalFiles>
        </v-row>
      </div>
      <!-- document only for section d'association  -->
      <v-row
        v-if="
          structure &&
          structure.EST_SectionAssociation &&
          this.$route.params.type === 'association'
        "
      >
        <v-col cols="5" class="d-flex align-center pt-0"
          ><h6>Joindre un règlement intérieur de la section*</h6>
        </v-col>
        <v-col cols="7" md="7" class="pt-0">
          <v-chip
            @click.prevent="modalIsOpen_DOC_Reglement = true"
            class="ma-2"
            small
            >{{ currentFileName("selectedFile_DOC_Reglement") }}</v-chip
          >
          <v-chip v-if="selectedFile_DOC_Reglement" class="ma-2" small>
            <span class="font-weight-bold mr-2">Date de signature:</span
            >{{ currentFileDate("selectedFile_DOC_Reglement") }}
          </v-chip>
        </v-col>
        <ModalFiles
          :key="modalKey_DOC_Reglement"
          :isOpen="modalIsOpen_DOC_Reglement"
          @onClose="closeModal('modalIsOpen_DOC_Reglement')"
          @onFileSelected="
            (file) => fileSelected('selectedFile_DOC_Reglement', file)
          "
          withValidationDate
        ></ModalFiles>
      </v-row>

      <!-- documents only for etablissement  -->
      <div
        v-if="
          this.$route.params.type !== 'association'
        "
      >
        <v-row>
          <v-col cols="5" class="d-flex align-center pt-0"
            ><h6>Joindre un mandat de prélévement SEPA*</h6>
          </v-col>
          <v-col cols="7" md="7" class="pt-0">
            <v-chip
              @click.prevent="modalIsOpen_DOC_SEPA = true"
              class="ma-2"
              small
              >{{ currentFileName("selectedFile_DOC_SEPA") }}</v-chip
            >
          </v-col>
          <ModalFiles
            :key="modalKey_DOC_SEPA"
            :isOpen="modalIsOpen_DOC_SEPA"
            @onClose="closeModal('modalIsOpen_DOC_SEPA')"
            @onFileSelected="(file) => fileSelected('selectedFile_DOC_SEPA', file)"
          ></ModalFiles>
        </v-row>
        <v-row>
          <v-col cols="5" class="d-flex align-center pt-0"
            ><h6>Joindre les diplômes des encadrants*</h6>
          </v-col>
          <v-col cols="7" md="7" class="pt-0">
            <v-chip
              @click.prevent="modalIsOpen_DOC_Diplomes = true"
              class="ma-2"
              small
              >{{ currentFileName("selectedFile_DOC_Diplomes") }}</v-chip
            >
            <v-chip v-if="selectedFile_DOC_Diplomes" class="ma-2" small>
              <span class="font-weight-bold mr-2">Date de signature:</span
              >{{ currentFileDate("selectedFile_DOC_Diplomes") }}
            </v-chip>
          </v-col>
          <ModalFiles
            :key="modalKey_DOC_Diplomes"
            :isOpen="modalIsOpen_DOC_Diplomes"
            @onClose="closeModal('modalIsOpen_DOC_Diplomes')"
            @onFileSelected="
              (file) => fileSelected('selectedFile_DOC_Diplomes', file)
            "
            withValidationDate
          ></ModalFiles>
        </v-row>
        <v-row>
          <v-col cols="5" class="d-flex align-center pt-0"
            ><h6>Joindre une copie des cartes professionnelles*</h6>
          </v-col>
          <v-col cols="7" md="7" class="pt-0">
            <v-chip
              @click.prevent="modalIsOpen_DOC_Cartes = true"
              class="ma-2"
              small
              >{{ currentFileName("selectedFile_DOC_Cartes") }}</v-chip
            >
            <v-chip v-if="selectedFile_DOC_Cartes" class="ma-2" small>
              <span class="font-weight-bold mr-2">Date de signature:</span
              >{{ currentFileDate("selectedFile_DOC_Cartes") }}
            </v-chip>
          </v-col>
          <ModalFiles
            :key="modalKey_DOC_Cartes"
            :isOpen="modalIsOpen_DOC_Cartes"
            @onClose="closeModal('modalIsOpen_DOC_Cartes')"
            @onFileSelected="
              (file) => fileSelected('selectedFile_DOC_Cartes', file)
            "
            withValidationDate
          ></ModalFiles>
        </v-row>
        <v-row>
          <v-col cols="5" class="d-flex align-center pt-0"
            ><h6>Joindre une attestation d'assurance*</h6>
          </v-col>
          <v-col cols="7" md="7" class="pt-0">
            <v-chip
              @click.prevent="modalIsOpen_DOC_Attestation = true"
              class="ma-2"
              small
              >{{ currentFileName("selectedFile_DOC_Attestation") }}</v-chip
            >
            <v-chip v-if="selectedFile_DOC_Attestation" class="ma-2" small>
              <span class="font-weight-bold mr-2">Date de signature:</span
              >{{ currentFileDate("selectedFile_DOC_Attestation") }}
            </v-chip>
          </v-col>
          <ModalFiles
            :key="modalKey_DOC_Attestation"
            :isOpen="modalIsOpen_DOC_Attestation"
            @onClose="closeModal('modalIsOpen_DOC_Attestation')"
            @onFileSelected="
              (file) => fileSelected('selectedFile_DOC_Attestation', file)
            "
            withValidationDate
          ></ModalFiles>
        </v-row>
      </div>
      <div class="my-4">
        <v-row no-gutters>
          <v-btn dark @click.prevent="$emit('onPrevious')">Retour</v-btn>
          <v-btn type="submit" class="btn btn-primary ml-auto" :disabled="isDisabled"
            >Mettre à jour</v-btn
          >
        </v-row>
      </div>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import InputText from "@/components/utils/input";
import documentAffiliation from "@/components/Affiliations/Affiliation_StructureDocUpload.vue";
import ModalFiles from "@/components/utils/modals/fileUpload/fileUpload.vue";

import { sleep } from "@/plugins/utils";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    ValidateForm,
    ModalFiles,
    InputText,
  },
  props: {
    structure: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    documents: [],
    modalKey_DOC_SEPA: 99,
    modalKey_DOC_Status: 88,
    modalKey_DOC_RIB: 77,
    modalKey_DOC_JO: 66,
    modalKey_DOC_RecepissePrefectoral: 55,
    modalKey_DOC_Reglement: 44,
    modalKey_DOC_Diplomes: 33,
    modalKey_DOC_Cartes: 22,
    modalKey_DOC_Attestation: 11,

    modalIsOpen_DOC_Status: false,
    modalIsOpen_DOC_RIB: false,
    modalIsOpen_DOC_SEPA: false,
    modalIsOpen_DOC_JO: false,
    modalIsOpen_DOC_RecepissePrefectoral: false,
    modalIsOpen_DOC_Reglement: false,
    modalIsOpen_DOC_Diplomes: false,
    modalIsOpen_DOC_Cartes: false,
    modalIsOpen_DOC_Attestation: false,

    selectedFile_DOC_Status: false,
    selectedFile_DOC_RIB: false,
    selectedFile_DOC_SEPA: false,
    selectedFile_DOC_JO: false,
    selectedFile_DOC_RecepissePrefectoral: false,
    selectedFile_DOC_Reglement: false,
    selectedFile_DOC_Diplomes: false,
    selectedFile_DOC_Cartes: false,
    selectedFile_DOC_Attestation: false,
    IBAN: "",
    BIC: "",
    mounted: false,
  }),

  async created() {
    // await this.getStructureActivite();
    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison"]),
    isDisabled() {
      if (
        this.structure?.EST_SectionAssociation === false &&
        this.$route.params.type === 'association' &&
        this.selectedFile_DOC_Status &&
        this.selectedFile_DOC_SEPA &&
        this.selectedFile_DOC_RIB &&
        this.selectedFile_DOC_JO &&
        this.selectedFile_DOC_RecepissePrefectoral &&
        this.IBAN &&
        this.BIC
      ) {
        return false;
      } else if (
        this.structure?.EST_SectionAssociation === true &&
        this.$route.params.type === 'association' &&
        this.selectedFile_DOC_Status &&
        this.selectedFile_DOC_RIB &&
        this.selectedFile_DOC_SEPA &&
        this.selectedFile_DOC_JO &&
        this.selectedFile_DOC_RecepissePrefectoral &&
        this.selectedFile_DOC_Reglement &&
        this.IBAN &&
        this.BIC
      ) {
        return false;
      } else if (
        this.$route.params.type !== 'association' &&
        this.selectedFile_DOC_Status &&
        this.selectedFile_DOC_RIB &&
        this.selectedFile_DOC_SEPA &&
        this.selectedFile_DOC_Diplomes &&
        this.selectedFile_DOC_Cartes &&
        this.selectedFile_DOC_Attestation &&
        this.IBAN &&
        this.BIC
      ) {
        return false;
      }
      return true;
    },
    currentFileName: (app) =>
      function (stateFile) {
        if (this[stateFile]) {
          return this[stateFile].file.name;
        }
        return "Veuillez choisir un fichier";
      },

    currentFileDate: (app) =>
      function (stateFile) {
        if (this[stateFile]) {
          return this.$moment(this[stateFile].validationDate).format(
            "DD/MM/YYYY"
          );
        }
        //if(this.document) return this.$moment(this.document.DateValidation).format('DD/MM/YYYY')
        return "";
      },
  },

  methods: {
    ...mapActions("structure", []),

    ...mapActions("basics", ["getListTypeActivite"]),

    async submit() {
      let documents = {};
      if (
          this.structure?.EST_SectionAssociation === false &&
          this.$route.params.type === 'association'
      ) {
        documents.DOC_Status = this.selectedFile_DOC_Status;
        documents.DOC_RIB = this.selectedFile_DOC_RIB;
        documents.DOC_SEPA = this.selectedFile_DOC_SEPA;
        documents.DOC_JO = this.selectedFile_DOC_JO;
        documents.DOC_RecepissePrefectoral = this.selectedFile_DOC_RecepissePrefectoral;
        documents.IBAN = this.IBAN;
        documents.BIC = this.BIC;
      } else if (
          this.structure?.EST_SectionAssociation === true &&
          this.$route.params.type === 'association'
      ) {
        documents.DOC_Status = this.selectedFile_DOC_Status;
        documents.DOC_RIB = this.selectedFile_DOC_RIB;
        documents.DOC_SEPA = this.selectedFile_DOC_SEPA;
        documents.DOC_JO = this.selectedFile_DOC_JO;
        documents.DOC_RecepissePrefectoral = this.selectedFile_DOC_RecepissePrefectoral;
        documents.DOC_Reglement = this.selectedFile_DOC_Reglement;
        documents.IBAN = this.IBAN;
        documents.BIC = this.BIC;
      } else if (
          this.$route.params.type !== 'association'
      ) {
        documents.DOC_Status = this.selectedFile_DOC_Status;
        documents.DOC_RIB = this.selectedFile_DOC_RIB;
        documents.DOC_SEPA = this.selectedFile_DOC_SEPA;
        documents.DOC_Diplomes = this.selectedFile_DOC_Diplomes;
        documents.DOC_Cartes = this.selectedFile_DOC_Cartes;
        documents.DOC_Attestation = this.selectedFile_DOC_Attestation;
        documents.IBAN = this.IBAN;
        documents.BIC = this.BIC;
      }
      this.$emit("onSetData", { documents });
      this.$emit("onNext");
    },

    closeModal(modalBool) {
      this[modalBool] = false;
    },
    fileSelected(fileState, file) {
      this[fileState] = file;
    },
    async setData() {
      return;
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
