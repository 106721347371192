<template>
  <div>
    <h3>Etape 1 - Saisie des informations générales</h3>
    <ValidateForm
      v-if="mounted"
      @validated="submit"
      @keydown.enter.prevent="preventKey"
    >
      <RadioGroup
          name="EST_SectionAssociation"
          v-model="structure.EST_SectionAssociation"
          :checked="structure.EST_SectionAssociation"
          :options="[{ value:false, label:'Club'}, {value: true, label: 'Section de club' }]"
          rules="required"
          label="Type d'association"
          v-if="this.$route.params.type === 'association'"
      ></RadioGroup>
      <InputText
        v-model="structure.NomStructure"
        rules="required|min:4"
        label="Nom de la structure"
      ></InputText>

      <InputText
        v-model="structure.NomStructureComplement"
        label="Complément de nom"
      ></InputText>

      <Textarea
        v-model="structure.INFO_Presentation"
        rules="required|min:20"
        label="Texte de présentation de la structure"
      ></Textarea>

      <InputSelect
        label="Première saison d'affiliation souhaitée"
        :items="saisonSelectData"
        v-model="affiliations.ID_Premiere_Saison"
        item_text="NomSaison"
        item_value="id"
        rules="required"
        @onChange="saisonChanged"
      ></InputSelect>

      <InputText
        v-model="structure.JUR_Siret"
        label="Numéro SIRET"
        rules="siret"
        type="number"
      ></InputText>

      <InputText
        v-model="structure.JUR_AgrementJeunesseSports"
        label="Agrément jeunesse et sport"
        inputStyle="width: auto"
      ></InputText>

      <InputText
        type="number"
        step="0.1"
        v-model="affiliations.SAL_EquivalentTempsPlein"
        label=" Nombre d'équivalents temps plein dans mon club/section de club"
        rules="required|max:3"
        inputStyle="width: auto"
        :minValue="0"
      ></InputText>

      <InputSelect
        label="Si la structure a des salariés, quel est l'organisme de la formation professionnelle"
        :items="allCollecteur"
        v-model="affiliations.ID_CollecteurFormationProfessionnelle"
        item_text="NomCollecteur"
        item_value="id"
        rules="required"
      ></InputSelect>

      <div>
        <InputSelectMultiple
          class="force-w-100"
          v-model="activites"
          :items="allActivities"
          :multiple="true"
          item_value="id"
          rules="required"
          item_text="LIST_ActiviteType.NomActivite"
          label="Activités proposées par mon club"
          placeholder="Sélectionnez une ou plusieurs activités"
        ></InputSelectMultiple>

        <v-row class="justify-content-end" v-if="activites.length > 0">
          <v-col cols="12" md="9">
            <v-card outlined>
              <v-list dense>
                <v-list-item-group multiple>
                  <v-list-item
                    v-for="(item, index) in activites"
                    :key="index"
                    @click="TogglePrimaryActivity(item.ID_Activite)"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.LIST_ActiviteType.NomActivite"
                      ></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon
                        :color="
                          item.EST_ActivitePrincipale
                            ? 'yellow'
                            : 'grey lighten-1'
                        "
                      >
                        mdi-star
                      </v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-card-title class="justify-content-end">
                <v-icon size="medium" color="yellow"> mdi-star </v-icon>
                <span class="caption ml-2">Activité principale</span>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>

        <RadioGroup
          name="CLUB_EST_AcceuillantHandicape"
          v-model="affiliations.CLUB_EST_AcceuillantHandicape"
          :checked="affiliations.CLUB_EST_AcceuillantHandicape"
          :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
          rules="required"
          label="Accueil de personnes en situation de handicap"
        ></RadioGroup>

        <RadioGroup
          name="CLUB_EST_AcceuillantMineur"
          v-model="affiliations.CLUB_EST_AcceuillantMineur"
          :checked="affiliations.CLUB_EST_AcceuillantMineur"
          :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
          label="Mineurs acceptés"
          rules="required"
        ></RadioGroup>

        <InputText
          v-model="affiliations.CLUB_AcceuillantMineurAge"
          label="Âge minimum"
          type="number"
          ref="mineurAge"
          rules="required"
          :key="mineurAgeInput"
          inputStyle="width: auto"
          :minValue="1"
        ></InputText>

        <RadioGroup
          name="CLUB_EST_AcceptePassSportCulture"
          v-model="affiliations.CLUB_EST_AcceptePassSportCulture"
          :checked="affiliations.CLUB_EST_AcceptePassSportCulture"
          :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
          label="Propose les passeports escalade"
          rules="required"
        ></RadioGroup>

        <RadioGroup
          name="CLUB_EST_PretMateriel"
          v-model="affiliations.CLUB_EST_PretMateriel"
          :checked="affiliations.CLUB_EST_PretMateriel"
          :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
          label="Prêt de matériel (EPI)"
          rules="required"
        ></RadioGroup>

        <RadioGroup
          name="CLUB_EST_ConventionAllocationFamilliale"
          v-model="affiliations.CLUB_EST_ConventionAllocationFamilliale"
          :checked="affiliations.CLUB_EST_ConventionAllocationFamilliale"
          :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
          label="Convention avec les Caisses d'Allocation Familiales"
          rules="required"
        ></RadioGroup>

        <RadioGroup
          name="CLUB_EST_AccepteChequesVacances"
          v-model="affiliations.CLUB_EST_AccepteChequesVacances"
          :checked="affiliations.CLUB_EST_AccepteChequesVacances"
          :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
          label="Accepte les chèques vacances"
          rules="required"
        ></RadioGroup>

        <RadioGroup
          name="CLUB_EST_AccepteCouponSport"
          v-model="affiliations.CLUB_EST_AccepteCouponSport"
          :checked="affiliations.CLUB_EST_AccepteCouponSport"
          :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
          label="Accepte les coupons sport"
          rules="required"
        ></RadioGroup>

        <RadioGroup
          name="CLUB_EST_PreparePasseport"
          v-model="affiliations.CLUB_EST_PreparePasseport"
          :checked="affiliations.CLUB_EST_PreparePasseport"
          :options="[{ value:true, label:'Oui'}, {value: false, label: 'Non' }]"
          label="Accepte les pass'sport culture"
          rules="required"
        ></RadioGroup>
      </div>

      <div class="my-4">
        <v-row no-gutters>
          <v-btn
            :disabled="isDisabled"
            type="submit"
            class="btn btn-primary ml-auto"
            >Mettre à jour</v-btn
          >
        </v-row>
      </div>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import InputText from "@/components/utils/input";
import Textarea from "@/components/utils/textarea.vue";
import InputSelect from "@/components/utils/select.vue";
import InputSelectMultiple from "@/components/utils/selectWithInput.vue";

import RadioGroup from "@/components/utils/radioGroup.vue";

import { mapActions, mapGetters } from "vuex";

import {
  PRODUCT_AFFILIATION_CLUB,
  PRODUCT_AFFILIATION_EA,
  OPTION_AFFILIATION_0,
  getPrices
} from "@/plugins/productService";

export default {
  components: {
    InputText,
    Textarea,
    InputSelect,
    RadioGroup,
    ValidateForm,
    InputSelectMultiple,
  },

  data: () => ({
    types_activities: [],
    structure: {},
    activites: [],
    affiliations: {},
    mounted: false,
    allCollecteur: [],
    mineurAgeInput: 0,
    saisonSelectData: [],
    favoriteActivity: null,
    ID_StructureType: null,
  }),

  async created() {
    // await this.getStructureActivite();
    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    ...mapGetters("saisons", ["currentSaison", "nextSaison", 'getSaison']),
    isDisabled() {
      if (
        this.structure.NomStructure &&
        this.structure.NomStructure.length > 3 &&
        this.structure.INFO_Presentation &&
        this.structure.INFO_Presentation.length > 10 &&
        this.affiliations.ID_Premiere_Saison &&
        this.favoriteActivity !== null
        // this.structure.JUR_Siret  &&
        // this.structure.JUR_AgrementJeunesseSports  &&

        // ( this.activites && this.activites.length >= 1 ) &&

        // // (this.affiliations.Siret && this.affiliations.Siret.length > 3) &&
        // this.affiliations.SAL_EquivalentTempsPlein != null &&
        // this.affiliations.ID_CollecteurFormationProfessionnelle != null &&
        // this.affiliations.CLUB_EST_AcceuillantHandicape != null &&
        // this.affiliations.CLUB_EST_AcceuillantMineur != null &&
        // this.affiliations.CLUB_AcceuillantMineurAge &&
        // this.affiliations.CLUB_EST_AcceptePassSportCulture != null &&
        // this.affiliations.CLUB_EST_PretMateriel != null &&
        // this.affiliations.CLUB_EST_ConventionAllocationFamilliale != null  &&
        // this.affiliations.CLUB_EST_AccepteChequesVacances != null &&
        // this.affiliations.CLUB_EST_AccepteCouponSport != null &&
        // this.affiliations.CLUB_EST_PreparePasseport != null
      ) {
        return false;
      }
      return true;
    },

    allActivities() {
      return this.types_activities.map((obj) => ({
        EST_ActivitePrincipale: false,
        ID_Activite: obj.id,
        LIST_ActiviteType: {
          ...obj,
        },
      }));
    },

    age_minimum() {
      return this.affiliations.CLUB_AcceuillantMineurAge;
    },
  },

  watch: {
    age_minimum(newVal, old) {
      if (newVal.length > 2) {
        this.affiliations.CLUB_AcceuillantMineurAge = newVal.slice(0, 2);
        this.$refs.mineurAge.setValue(
          this.affiliations.CLUB_AcceuillantMineurAge
        );
      }
    },
  },

  methods: {
    ...mapActions("structure", [
      "updatePresentationStructure",
      "updateInfoPresentationStructure",
    ]),

    ...mapActions("basics", [
      "getListTypeActivite",
      "getListCollecteurFormationProfessionnelle",
      "getListStructureType",
    ]),

    preventKey() {
      console.log("fdfdfdfdfsklfdskflfklnklfnsdlk");
    },

    async saisonChanged(saison) {
      let season = this.getSaison(parseInt(saison, 10));

      let prices = await getPrices({
        'seasonId': season.id,
        'structureId': 1318,
        'productId': this.$route.params.type === "association" ? PRODUCT_AFFILIATION_CLUB : PRODUCT_AFFILIATION_EA,
        'options': OPTION_AFFILIATION_0,
        'date': season.id !== this.currentSaison.id ? season.DateDebutSaison : undefined,
      });

      let tarifStructure = prices.reduce((carry, price) => carry + price.value, 0);

      this.$emit("onSetData", { tarifStructure: tarifStructure });
    },

    async submit() {
      console.log("this.affiliations ========", this.affiliations);
      this.$emit("onSetData", {
        activites: this.activites,
        structure: this.structure,
        affiliation: this.affiliations,
      });

      this.$emit("onNext");
    },

    async setData() {
      this.types_activities = await this.getListTypeActivite();
      this.allCollecteur =
        await this.getListCollecteurFormationProfessionnelle();

      if (this.currentSaison) {
        this.saisonSelectData.push(this.currentSaison);
      }

      if (this.nextSaison && this.nextSaison.ID_EtapeSaison === 3) {
        this.saisonSelectData.push(this.nextSaison);
      }

      return;
    },

    TogglePrimaryActivity(itemId) {
      this.favoriteActivity = itemId;
      this.selectedActivite = this.activites.find(
        (obj) => obj.ID_Activite == itemId
      );
      this.activites.forEach((obj) => (obj.EST_ActivitePrincipale = false));
      this.selectedActivite.EST_ActivitePrincipale = true;
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
