<template>
    <v-row>
        <v-col cols="12" class="text-center">
            <h4>Merci, nous vous recontacterons dans les plus brefs délais.</h4>
        </v-col>
    </v-row>
</template>


<script>
export default {

    components: {  },

    data: () => ({
        
        loading: false
    }),
    
    computed: {

    },

    methods: {
       
    },

    props: {
        
    }
}
</script>

