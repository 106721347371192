<template>
  <div>
    <h3>Etape 2 - Informations de contact</h3>
    <ValidateForm v-if="mounted" @validated="submit" :key="refreshForm">
      <v-row>
        <v-col cols="12" md="6">
          <h6>Correspondant</h6>

          <InputText
            v-model="utilisateurs.correspondant.CT_Nom"
            rules="required|min:2|max:250|regexname|dashCounter"
            label="Nom"
          ></InputText>

          <InputText
            v-model="utilisateurs.correspondant.CT_Prenom"
            rules="required|min:2|max:250|regexname"
            label="Prénom"
          ></InputText>

          <InputText
            v-model="utilisateurs.correspondant.CT_Email"
            rules="required|min:0|max:50|email"
            label="Email"
          ></InputText>
        </v-col>

        <v-col cols="12" md="6">
          <h6>Adresse de correspondance</h6>

          <AddressAutocomplete
            :item="adresse.Adresse1"
            rules="required|min:6|max:50"
            label="Adresse"
            :minimum_characters="3"
            ref="adresseAuto"
            @onSelected="adresseSelected"
            :restrictCountry="restrictCountry"
          ></AddressAutocomplete>

          <InputText
            v-model="adresse.Adresse2"
            rules="min:0|max:50"
            label="Complément d'adresse"
          ></InputText>

          <InputText
            v-model="adresse.CodePostal"
            rules="required|digits:5"
            label="Code postal"
            @input="watchCodePostal"
          ></InputText>
          <InputText
            v-model="adresse.Ville"
            rules="required|min:1|max:60"
            label="Ville"
          ></InputText>

          <selectInput
            :items="departementsList"
            v-model="adresse.Departement"
            @change="departementSelected"
            label="Département"
            rules="required"
            item_text="NomCode"
            item_value="id"
          />

          <countriesSelect
            v-model="currentPays"
            rules="required"
            label="Pays"
            value="France"
            @onChange="onPaysChanged"
          ></countriesSelect>
        </v-col>
      </v-row>

      <v-row v-if="this.$route.params.type === 'association'">
        <v-col cols="12">
          <h6>Liste des dirigeants déclarés</h6>
          <v-row>
            <v-col cols="12" md="4">
              <div>
                <span class="font-weight-bold">Président:</span>
              </div>
              <InputText
                v-model="utilisateurs.president.CT_Nom"
                rules="required|min:2|max:250|regexname|dashCounter"
                label="Nom"
              ></InputText>

              <InputText
                v-model="utilisateurs.president.CT_Prenom"
                rules="required|min:2|max:250|regexname"
                label="Prénom"
              ></InputText>

              <InputText
                v-model="utilisateurs.president.CT_Email"
                rules="required|min:0|max:50|email"
                label="Email"
              ></InputText>
            </v-col>
            <v-col cols="12" md="4">
              <div>
                <span class="font-weight-bold">Secrétaire:</span>
              </div>
              <InputText
                v-model="utilisateurs.secretaire.CT_Nom"
                rules="required|min:2|max:250|regexname|dashCounter"
                label="Nom"
              ></InputText>

              <InputText
                v-model="utilisateurs.secretaire.CT_Prenom"
                rules="required|min:2|max:250|regexname"
                label="Prénom"
              ></InputText>

              <InputText
                v-model="utilisateurs.secretaire.CT_Email"
                rules="required|min:0|max:50|email"
                label="Email"
              ></InputText>
            </v-col>
            <v-col cols="12" md="4">
              <div>
                <span class="font-weight-bold">Trésorier:</span>
              </div>
              <InputText
                v-model="utilisateurs.tresorier.CT_Nom"
                rules="required|min:2|max:250|regexname|dashCounter"
                label="Nom"
              ></InputText>

              <InputText
                v-model="utilisateurs.tresorier.CT_Prenom"
                rules="required|min:2|max:250|regexname"
                label="Prénom"
              ></InputText>

              <InputText
                v-model="utilisateurs.tresorier.CT_Email"
                rules="required|min:0|max:50|email"
                label="Email"
              ></InputText>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="my-4">
        <v-row no-gutters>
          <v-btn dark @click.prevent="$emit('onPrevious')">Retour</v-btn>
          <v-btn
            :disabled="isDisabled"
            type="submit"
            class="btn btn-primary ml-auto"
            >Mettre à jour</v-btn
          >
        </v-row>
      </div>
    </ValidateForm>
  </div>
</template>

<script>
import ValidateForm from "@/components/utils/form";
import InputText from "@/components/utils/input";
import AddressAutocomplete from "@/components/utils/adresseAutocomplete";
import selectInput from "@/components/utils/selectWithInput.vue";
import countriesSelect from "@/components/utils/select__countries.vue";

import { sleep, slugify } from "@/plugins/utils";
import { mapActions } from "vuex";
import { capitalizeFirstLetter, trimAndCapitalizeFirstLetter, trimAndUppercaseAllLetters } from "@/plugins/utils";

export default {
  components: {
    InputText,
    ValidateForm,
    AddressAutocomplete,
    countriesSelect,
    selectInput,
  },

  data: () => ({
    restrictCountry: "FR",
    refreshForm: 99,
    departementsList: "",
    currentPays: {
      NomPays: "France",
    },
    depo: null,
    adresse: {},
    adresseValidated: false,
    utilisateurs: {
      correspondant: {},
      president: {},
      secretaire: {},
      tresorier: {},
    },
    mounted: false,
  }),

  async created() {
    this.types_activities = await this.getListTypeActivite();
    // await this.getStructureActivite();
    await this.setData();
    this.mounted = true;
    return;
  },

  computed: {
    isDisabled() {
      if (
        this.$route.params.type === 'association' &&
        this.adresseValidated &&
        this.utilisateurs.correspondant.CT_Nom &&
        this.utilisateurs.correspondant.CT_Prenom &&
        this.utilisateurs.correspondant.CT_Email &&
        this.utilisateurs.president.CT_Nom &&
        this.utilisateurs.president.CT_Prenom &&
        this.utilisateurs.president.CT_Email &&
        this.utilisateurs.secretaire.CT_Nom &&
        this.utilisateurs.secretaire.CT_Prenom &&
        this.utilisateurs.secretaire.CT_Email &&
        this.utilisateurs.tresorier.CT_Nom &&
        this.utilisateurs.tresorier.CT_Prenom &&
        this.utilisateurs.tresorier.CT_Email
      ) {
        return false;
      }
      if (
          this.$route.params.type !== 'association' &&
          this.adresseValidated &&
          this.utilisateurs.correspondant.CT_Nom &&
          this.utilisateurs.correspondant.CT_Prenom &&
          this.utilisateurs.correspondant.CT_Email
      ) {
        return false;
      }
      return true;
    },
  },

  methods: {
    ...mapActions("structure", [
      "updatePresentationStructure",
      "updateInfoPresentationStructure",
    ]),

    ...mapActions("basics", [
      "getListTypeActivite",
      "getListStructureCT",
      "getDepartementsList",
      "insertDepartement",
    ]),

    async submit() {
      this.utilisateurs.correspondant.CT_Nom = trimAndUppercaseAllLetters(this.utilisateurs.correspondant.CT_Nom);
      this.utilisateurs.correspondant.CT_Prenom = trimAndCapitalizeFirstLetter(this.utilisateurs.correspondant.CT_Prenom);
      if (this.$route.params.type === 'association') {
        this.utilisateurs.president.CT_Nom = trimAndUppercaseAllLetters(this.utilisateurs.president.CT_Nom);
        this.utilisateurs.president.CT_Prenom = trimAndCapitalizeFirstLetter(this.utilisateurs.president.CT_Prenom);

        this.utilisateurs.secretaire.CT_Nom = trimAndUppercaseAllLetters(this.utilisateurs.secretaire.CT_Nom);
        this.utilisateurs.secretaire.CT_Prenom = capitalizeFirstLetter(this.utilisateurs.secretaire.CT_Prenom);

        this.utilisateurs.tresorier.CT_Nom = trimAndUppercaseAllLetters(this.utilisateurs.tresorier.CT_Nom);
        this.utilisateurs.tresorier.CT_Prenom = capitalizeFirstLetter(this.utilisateurs.tresorier.CT_Prenom);
      }

      this.$emit("onSetData", {
        utilisateurs: this.utilisateurs,
        adresse: this.adresse,
      });

      this.$emit("onNext");
    },

    async adresseSelected(item) {
      if (typeof item === "string") return (this.adresse.Adresse1 = item);
      await sleep(10);
      console.log(item);
      let adresse1 = item[0].long_name + " " + item[1].long_name;
      this.$refs.adresseAuto.currentValue = adresse1.toUpperCase();
      this.adresse.Adresse1 = adresse1.toUpperCase();
      this.adresse.Adresse2 = "";
      this.adresse.Ville = item[2].long_name.toUpperCase();
      this.adresse.CodePostal = item[6] ? item[6].long_name.toUpperCase() : "";
      // if(item[3] && item[3].long_name) {
      //   let stripDpt = slugify(item[3].long_name)
      //   stripDpt = stripDpt.toUpperCase().replace('_', " ").replace('-', " ")
      //   this.adresse.Departement = this.departementsList.find(dpt => dpt.NomStructure == stripDpt)
      // }

      this.refreshForm++;
      this.adresseValidated = true;
    },

    async departementSelected(item) {
      this.adresse.Departement = item;
      console.log("this.adresse.Departement", this.adresse.Departement);
      // if (typeof item === "string") return (this.adresse.Adresse1 = item);
      // console.log(item)
    },

    async watchCodePostal(item) {
      let codePostal = item.toString().slice(0, 2);
      let depts = await this.getDepartementsList();
      let departement = depts.find((dep) => dep.CodeDepartement == codePostal);
      this.adresse.Departement = {...departement, NomCode: `${departement.CodeDepartement} - ${departement.NomDepartement}`};
      this.$forceUpdate()
    },

    onPaysChanged(item) {
      this.adresse.ID_Pays = item.id;
      this.adresse.ADR_Pays = item;
      this.restrictCountry = item.CodePays;
      this.$refs.adresseAuto.setRestriction(this.restrictCountry);
      this.currentPays = item;
    },

    async setData() {
      let depts = await this.getDepartementsList();
      // this.departementsList = depts.filter((dep) => dep.STR_Structure.length);
      this.departementsList = depts.map((dep) => ({
        ...dep,
        NomCode: `${dep.CodeDepartement} - ${dep.NomDepartement}`,
      }));

      return;
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
